._2xTplyAjARswn7VUflwp5X {
  color: #6b778c;
}

.UapkQHfU3FpdbfOgah92H {
  margin-bottom: 32px;
  margin-bottom: 2rem;
}

.UapkQHfU3FpdbfOgah92H .WQm8eph_y5NJ-SQ9HDwMn {
  font-size: 12px;
  margin-bottom: 4px;
  margin-bottom: .25rem;
  font-weight: 600;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.UapkQHfU3FpdbfOgah92H small {
  padding-left: 4px;
  padding-left: .25rem;
  font-weight: normal;
}

.UapkQHfU3FpdbfOgah92H .lsbkhNPVsrc4AOE815dW5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.UapkQHfU3FpdbfOgah92H .lsbkhNPVsrc4AOE815dW5>.p6g8uSKtJvzyCmNQbKYQ1 {
  width: 10%;
  line-height: 40px;
  vertical-align: middle;
  text-align: center;
  margin: 0 10px;
}

.UapkQHfU3FpdbfOgah92H .lsbkhNPVsrc4AOE815dW5>._3hns9AjCqRrrlsgglJxVFi {
  width: 45%;
}

.UapkQHfU3FpdbfOgah92H .lsbkhNPVsrc4AOE815dW5>._3hns9AjCqRrrlsgglJxVFi>._160eXeDkZpdkRiWGifZUg0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.UapkQHfU3FpdbfOgah92H .lsbkhNPVsrc4AOE815dW5>._3hns9AjCqRrrlsgglJxVFi>._160eXeDkZpdkRiWGifZUg0>._3PCd34aFIQLsAjdWWy3tMO {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.UapkQHfU3FpdbfOgah92H .lsbkhNPVsrc4AOE815dW5>._3hns9AjCqRrrlsgglJxVFi>._160eXeDkZpdkRiWGifZUg0>.HTwgtOWNAQe4c8ZxoQEk3 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 14px;
}

@media screen and (max-width: 768px) {
  .UapkQHfU3FpdbfOgah92H .lsbkhNPVsrc4AOE815dW5 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .UapkQHfU3FpdbfOgah92H .lsbkhNPVsrc4AOE815dW5>.p6g8uSKtJvzyCmNQbKYQ1 {
    width: 100%;
    text-align: left;
    margin: .5rem 0;
  }

  .UapkQHfU3FpdbfOgah92H .lsbkhNPVsrc4AOE815dW5>._3hns9AjCqRrrlsgglJxVFi {
    width: 100%;
  }
}

