._2ZUfc3exqVAVq82-RXmmRy {
  min-height: 25%;
}

._2wOD5aJH3F-IjQcHIh8Udc {
  background-color: #fff;
  line-height: 40px;
  padding: 1px 10px 0;
}

._3qy5Vy5niTA5YAR6-x9PZ0 {
  background-color: #42526e;
  color: #fff;
}

._34-pLs8rXfQ3pFVMFFpFqG {
  font-size: 14px;
  border: 1px solid #bbb;
  border-radius: 3px;
  padding: 1px 10px 0;
  height: 40px;
  width: 100% !important;
  font-size: 14px;
}

